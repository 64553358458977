import { routes } from '@gijirokukun/shared'
import { NextRouter } from 'src/api/router'

const handleAuthInternalError = (error: any) => {
  try {
    // ブロッキング関数のエラーかどうかチェック
    // https://firebase.google.com/docs/auth/extend-with-blocking-functions#blocking_registration_or_sign-in
    // stringにjsonが埋め込まれている最悪設計。無理矢理パースする
    const blockingErrorRegex = /.*?({.*}).*/
    const parsedBlockingError = blockingErrorRegex.exec(error.message)
    const parsedBlockingErrorJson = JSON.parse(parsedBlockingError![1])
    const message = parsedBlockingErrorJson['error']['message']
    if (typeof message !== 'string') throw new Error()
    alert(message)
  } catch (parseError) {
    console.debug(parseError)
    console.error(error, error?.code)
    alert('不明なエラー1：' + (error?.message ?? error))
  }
}

export const alertSignUpError = (error: any) => {
  switch (error?.code) {
    case 'auth/invalid-email':
      alert('メールアドレスの形式が間違っています')
      break
    case 'auth/email-already-in-use':
      alert('このメールアドレスは既に別のアカウントで使用されています。')
      break
    case 'auth/weak-password':
      alert(
        '入力したパスワードが十分に強力ではありませんでした。パスワードの長さを長くするなどしてもう一度お試しください。'
      )
      break
    default:
      console.error(error, error?.code)
      alert('不明なエラー2：' + (error?.message ?? error))
      break
  }
}

export const alertSignInError = (error: any) => {
  switch (error?.code) {
    case 'auth/invalid-email':
      alert('メールアドレスの形式が間違っています')
      break
    case 'auth/user-disabled':
      alert('このユーザーは管理者によって無効にされています')
      break
    case 'auth/user-not-found':
      alert(
        'ユーザーが見つかりません。Googleでアカウント登録をした場合は「Googleでログイン」からログインしてください'
      )
      break
    case 'auth/wrong-password':
      alert('パスワードが間違っています')
      break
    case 'auth/too-many-requests':
      alert(
        'パスワードの入力に複数回失敗したため、このアカウントを一時的にロックしました。しばらく待ってから再度試すか、パスワードをリセットしてください。'
      )
      break
    case 'app/invalid-organization':
      alert(
        '現在このアカウントは組織により無効になっているため、ログインすることが出来ません。'
      )
      break
    case 'auth/internal-error': {
      handleAuthInternalError(error)
      break
    }
    default:
      console.error(error, error?.code)
      alert('不明なエラー3：' + (error?.message ?? error))
      break
  }
}

export const alertVerifyMultiFactorPhoneNumberError = (
  error: any,
  router: NextRouter
) => {
  switch (error?.code) {
    case 'auth/captcha-check-failed': {
      alert(
        '安全でないデバイスからのリクエストを検出しました。別のデバイスでお試しください。'
      )
      void router.push(routes.login)
      break
    }
    case 'auth/invalid-phone-number': {
      alert('無効な形式の電話番号です。電話番号を確認して再度お試しください。')
      void router.reload()
      break
    }
    case 'auth/missing-phone-number': {
      alert('無効な電話番号です。電話番号を確認して再度お試しください。')
      void router.reload()
      break
    }
    case 'auth/quota-exceeded': {
      alert(
        'リクエストの最大試行回数に達しました。時間を置いてもう一度お試しください。'
      )
      void router.reload()
      break
    }
    case 'auth/user-disabled': {
      // FIXME: このエラーがよくわからない。電話番号に対応するユーザーって何？
      console.error(error)
      alert('この電話番号は無効です。別の電話番号でお試しください。')
      void router.reload()
      break
    }
    case 'auth/maximum-second-factor-count-exceeded': {
      alert(
        'これ以上２段階認証を追加することは出来ません。お困りの場合は、サポートにお問い合わせください。'
      )
      void router.reload()
      break
    }
    case 'auth/second-factor-already-in-use': {
      alert('この電話番号は既に登録されています。')
      void router.reload()
      break
    }
    case 'auth/unsupported-first-factor': {
      // メアド認証とGoogle認証はサポートされているはず
      console.error(error)
      alert(
        '２段階認証がサポートされていないアカウントです。お困りの場合は、サポートにお問い合わせください。'
      )
      void router.reload()
      break
    }
    case 'auth/unverified-email': {
      // メアド認証されていない場合、この画面を開けないはず
      console.error(error)
      alert(
        'メールアドレスが認証されていません。メールアドレスの認証が完了してからもう一度お試しください。'
      )
      void router.reload()
      break
    }
    case 'auth/requires-recent-login': {
      alert('この操作を行うには再度ログインが必要です。')
      void router.push(routes.login)
      break
    }
    default: {
      console.error(error, error?.code)
      alert('不明なエラー4：' + (error?.message ?? error))
      void router.reload()
      break
    }
  }
}

export const alertUnEnrollMultiFactorError = (
  error: any,
  router: NextRouter
) => {
  switch (error?.code) {
    case 'auth/captcha-check-failed': {
      alert(
        '安全でないデバイスからのリクエストを検出しました。別のデバイスでお試しください。'
      )
      break
    }
    case 'auth/quota-exceeded': {
      alert(
        'リクエストの最大試行回数に達しました。時間を置いてもう一度お試しください。'
      )
      break
    }
    case 'auth/unsupported-first-factor': {
      // メアド認証とGoogle認証はサポートされているはず
      console.error(error)
      alert(
        '２段階認証がサポートされていないアカウントです。お困りの場合は、サポートにお問い合わせください。'
      )
      break
    }
    case 'auth/unverified-email': {
      // メアド認証されていない場合、この画面を開けないはず
      console.error(error)
      alert(
        'メールアドレスが認証されていません。メールアドレスの認証が完了してからもう一度お試しください。'
      )
      break
    }
    case 'auth/requires-recent-login': {
      alert('この操作を行うには再度ログインが必要です。')
      void router.push(routes.login)
      break
    }
    default: {
      console.error(error, error?.code)
      alert('不明なエラー5：' + (error?.message ?? error))
      break
    }
  }
}

export const alertVerifyMultiFactorCodeError = (
  error: any,
  router: NextRouter
) => {
  switch (error?.code) {
    case 'auth/invalid-verification-code': {
      alert('認証コードが間違っています。もう一度お試しください。')
      void router.reload()
      break
    }
    case 'auth/second-factor-already-in-use': {
      alert('この電話番号は既に登録されています。')
      break
    }
    default: {
      console.error(error, error?.code)
      alert('不明なエラー6：' + (error?.message ?? error))
      break
    }
  }
}

/**
 * すべてカバー出来ているわけではない
 */
export const alertActionCommonError = (error: any, retry: () => void) => {
  switch (error?.code) {
    case 'auth/expired-action-code':
      alert('リンクの有効期限が切れています。もう一度最初からお試しください。')
      retry()
      break
    case 'auth/invalid-action-code':
      alert('セッションが切れました。もう一度最初からお試しください。')
      retry()
      break
    case 'auth/user-disabled':
      alert(
        'このユーザーは無効になっているため、操作を実行出来ません。お困りの場合は、サポートにお問い合わせください。'
      )
      retry()
      break
    case 'auth/user-not-found':
      alert('ユーザーが見つかりません。もう一度最初からお試しください。')
      retry()
      break
    case 'auth/internal-error': {
      handleAuthInternalError(error)
      retry()
      break
    }
    default:
      console.error(error, error?.code)
      alert('不明なエラー7：' + (error?.message ?? error))
      retry()
      break
  }
}
